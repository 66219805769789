import './Sidenav.css';
import { Link } from 'react-router-dom';
import '../Fonts/fonts.css';
import Header from '../Header/Header';
import { Box, Center } from '@chakra-ui/react';
import { useContext, useState, React, useEffect } from 'react';
import { AppContext } from '../core/app-context';
import { useHistory, Redirect } from 'react-router-dom';
import api from './../core/api';

function Sidenav({active}) {

	const [ appState, dispatch ] = useContext(AppContext);

    let history = useHistory();
    

	return (
		 <div id="mySidenav" class="sidenav">
                <Box p={7} >
                <h1 className='company_name'>{ appState.user.CompanyName}</h1> 
                <p className='menu_thing'>Menu</p>
                </Box>
        <li className={active==='home'?'actively':''} onClick={()=> history.push('/homepage')}>Overview</li>
        
  
                <li className={active==='sales'?'actively':''} onClick={()=> history.push('/salespage')}>Sales</li>
                
                <li className={active==='invoice'?'actively':''} onClick={()=> history.push('/invoicepage')}> Receipt Sales</li>

                < p style={{borderBottom:'1px solid #58678E', width:'227px', marginLeft:'16px', marginTop:'35px'}}></p>

                <li onClick={()=> history.push('/')}>Sign Out</li>
        </div>

	);
}

export default Sidenav;
