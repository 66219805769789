import { createContext, useReducer } from 'react';
import api from './api';


const localStorageKey = 'fiseti_storage';

if (!localStorage.getItem(localStorageKey)) {
    localStorage.setItem(localStorageKey, JSON.stringify({
        user: {},
        loading: false
    }));
}

if (!JSON.parse(localStorage.getItem(localStorageKey)).options) {
    localStorage.setItem(localStorageKey, JSON.stringify({
        ...JSON.parse(localStorage.getItem(localStorageKey)),
        options: {
            hideHeader: false
        },
    }));
}

let initialState = JSON.parse(localStorage.getItem(localStorageKey) ?? "{}");

export const AppContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case "setUser":
            let setUserState = { ...state, user: action.value };
            localStorage.setItem(localStorageKey, JSON.stringify(setUserState));
            return setUserState;
        case "lastMember":
            let lastMemberState = state;
            lastMemberState.lastMember = action.value;
            localStorage.setItem(localStorageKey, JSON.stringify(lastMemberState));
            return lastMemberState;
        case "loading":
            return {
                ...state,
                loading: state ? !state.loading : true
            };
        case "setLoading":
            return {
                ...state,
                loading: true
            };
        case "unsetLoading":
            return {
                ...state,
                loading: false
            };
        case "setState":
            return action.state;
        default:
            console.warn("invalid action");
            return state;
    }
};

export const StateProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={[state, dispatch]}>
            {props.children}
        </AppContext.Provider>
    );
}