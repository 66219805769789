import './HomePage.css';
import { Link } from 'react-router-dom';
import '../Fonts/fonts.css';
import Header from '../Header/Header';
import Sidenav from '../Sidenav/Sidenav';
import LineGraph from '../Graphs/LineGraph';
import { Box, Center } from '@chakra-ui/react';
import { useContext, useState, React, useEffect } from 'react';
import { AppContext } from '../core/app-context';
import { useHistory, Redirect } from 'react-router-dom';
import api from './../core/api';
import Pagination from './Pagination'

//USED REACT NATIVE CHART KIT for charts

function MainPage() {
	const [ appState, dispatch ] = useContext(AppContext);

	const [ itemSold, setItemSold ] = useState([]);
	const [ lowProducts, setLowProducts ] = useState([]);
	const [ tallyProducts, setTallyProducts ] = useState({});

	useEffect(() => {
		api
			.post('/api.php', {
				action: 'last-seven-days',
				body: { companyid: appState.user.CompanyID },
			})
			.then((result) => {
				console.log('item Sold', result.data.data);
				setItemSold(result.data.data);
			})
			.catch((err) => {
				console.warn(err);
			});
	}, []);

	useEffect(() => {
		api
			.post('/api.php', {
				action: 'get-low-qty',
				body: { companyid: appState.user.CompanyID },
			})
			.then((result) => {
				console.log('Low Products', result.data.data);
				setLowProducts(result.data.data);
			})
			.catch((err) => {
				console.warn(err);
			});
	}, []);

//PAGINATION BEGINS HERE
const [currentPage, setCurrentPage] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(6);
 const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

		   const paginate = pageNumber => setCurrentPage(pageNumber)

const handleNextPage = () => {
        if(currentPage ===lowProducts.length/ itemsPerPage){
                setCurrentPage(currentPage)

        } else {
               setCurrentPage(currentPage+1) 
        }
                
        }

 const handleLastPage = () => {

         if(currentPage ===1){
                setCurrentPage(currentPage)
         } else {
                 setCurrentPage(currentPage-1)
         }
        }

//PAGINATION ENDS HERE




	let total_sales = itemSold.reduce(function(prev, current) {
		return prev + current.ProductPrice * current.ProductQty;
	}, 0);

	let qty_sold = itemSold.length;

	useEffect(
		() => {
			let tempTallyProducts = {};

			for (var i = 0; i < itemSold.length; i++) {
				let currentProd = itemSold[i];

				if (currentProd.ProductName) {
					if (tempTallyProducts[currentProd.ProductName]) {
						//setTallyProducts({...tallyProducts,[currentProd.ProductName]:

						//tallyProducts[currentProd.ProductName]+1});
						tempTallyProducts[currentProd.ProductName] = tempTallyProducts[currentProd.ProductName] + 1;
					} else {
						//setTallyProducts({...tallyProducts,[currentProd.ProductName]:
						//1});
						tempTallyProducts[currentProd.ProductName] = 1;
					}
				}
			}
			setTallyProducts(tempTallyProducts);
		},
		[ itemSold ]
	);

	const top5Tally = Object.keys(tallyProducts)
		.map((key, keyIDX) => ({ quantity: tallyProducts[key], ProductName: key }))
		.sort((a, b) => {
			return parseInt(b.quantity) - parseInt(a.quantity);
		})
		.slice(0, 5);

	const productID = top5Tally.map((product, i) => <p key={'product_item' + i}>{i + 1}</p>);

	const productName = top5Tally.map((product) => (
		<p style={{ borderBottom: ' 1px solid #CCCFD8' }}>{product.ProductName}</p>
	));

	const productPrice = top5Tally.map((product) => (
		<p style={{ borderBottom: ' 1px solid #CCCFD8' }}>{product.quantity}</p>
	));

	return (
		<div className="main-page">
			<div className="header">
				<Header />
			</div>

			<aside className="left-side">
				<Sidenav active="home" />
			</aside>

			<label className="top_bar_label" htmlFor="top_bar">
				{' '}
				Overview<span className="top_span" style={{ display: 'block', lineHeight: '0px' }}>
					(from last 7 days)
				</span>
			</label>
			<div className="top_bar">
				<div style={{ width: '87%', height: '80%', marginLeft: '65px', marginTop: '8px' }}>
					<div className="block1">
						<header className="top_bar_block_heading">Total Sales (MT)</header>
						<main className="top_bar_block_amount">{total_sales}</main>
						<header
							style={{ marginTop: '16px', color: '#fff', opacity: '0' }}
							className="top_bar_block_heading"
						>
							Last week: <span>42 000</span>
						</header>
					</div>

					<div className="block2">
						<header className="top_bar_block_heading">Items Sold</header>
						<main className="top_bar_block_amount">{qty_sold}</main>
						<header
							style={{ marginTop: '16px', color: '#fff', opacity: '0' }}
							className="top_bar_block_heading"
						>
							Last week: <span>1 800</span>
						</header>
					</div>

					<div className="block3" />
				</div>
			</div>

			<div className="sales_trend">
				<h1 className="block_headers"> Sales Trend </h1>
				<div style={{ width: '80%', marginLeft: '40px' }}>
					<LineGraph />
				</div>
			</div>

			<div className="top_selling">
				<h1 className="block_headers">
					{' '}
					Top Selling{' '}
					<span className="top_selling_values" style={{ marginLeft: '80px' }}>
						7-day frequency
					</span>{' '}
				</h1>

				<div style={{ marginTop: '-20px' }}>
					<p className="top_selling_no" style={{ display: 'inline-block' }}>
						{productID}
					</p>

					<p
						className="top_selling_values"
						style={{ display: 'inline-block', width: '55%', textAlign: 'left' }}
					>
						{productName}
					</p>

					<p
						className="top_selling_values"
						style={{ display: 'inline-block', width: '20%', textAlign: 'right' }}
					>
						{productPrice}
					</p>
				</div>
			</div>

			{/* <div className="account_details">
				<Box style={{ width: '95%', height: '90%', marginLeft: '32px' }}>
					<p className="stock_control">Stock Control</p>

					<table>
						{lowProducts.slice(indexOfFirstItem, indexOfLastItem).map((e) => {
							return (
								<div className='stock_block'>
								<tr>
									<thead>
										<td className='stock_product_name'>{e.ProductName}</td>
									</thead>
									<tr>
										<td className='stock_quantity'>{e.Stock}</td>
									</tr>
								</tr>
								</div>
							);
						})}
					</table>

					<div style={{float:'right', marginTop:'18px'}}>
					<Pagination currentPage={currentPage} handleNextPage={handleNextPage} handleLastPage={handleLastPage} itemsPerPage={itemsPerPage} totalItems={lowProducts.length} paginate={paginate} />
					</div>
				</Box>
			</div> */}
		</div>
	);
}

export default MainPage;
