import './SalesPage.css';
import { Link } from 'react-router-dom';
import '../Fonts/fonts.css';
import Header from '../Header/Header';
import Sidenav from '../Sidenav/Sidenav';
import BarGraph from '../Graphs/BarGraph';
import PieGraph from '../Graphs/PieGraph';
import { Alert, Box, Center, IconButton } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from './../core/api';
import { AppContext } from '../core/app-context';
import Pagination from './Pagination'
//USED REACT NATIVE CHART KIT for charts

function SalesPage() {
	const [ appState, dispatch ] = useContext(AppContext);

const [items, setItems]= useState([]);

    const [currentPage, setCurrentPage] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(5);
 const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

		   const paginate = pageNumber => setCurrentPage(pageNumber)
const handleNextPage = () => {
        if(currentPage ===items.length/ itemsPerPage){
                setCurrentPage(currentPage)

        } else {
               setCurrentPage(currentPage+1) 
        }
                
        }

 const handleLastPage = () => {


         if(currentPage ===1){
                setCurrentPage(currentPage)
         } else {
                 setCurrentPage(currentPage-1)
         }
        }


	let history = useHistory();

	 const [date, setDate] = useState("");
        const [company_id, setCompanyID] = useState("");


        const [solditems, setSoldItems]= useState([]);
        const [totalsales, setTotalSales]= useState([]);

		const itis = [{CompanyID: "11",
ID: 3386,
ProductName: "Coke zero"
,ProductPrice: "20"
,ProductQty: "2"
,TimeStamp: "2021-11-24 23:10:02"}]


	const handleSubmit = () => {
                api
                    .post('/api.php', {
                        // action is the parameter that the api/gateway/actions.php files we usually use look for
                        action: 'sale-today',
                        // body is the other parameter that is used with all the variables that will be passed to DataManager.class.php
                        // functions.
                        body: {
                            date: date,
                            companyid: appState.user.CompanyID
                        }
        
                    })
                    .then(function (response) {
                        console.log(response)
                        if (response.data.status === 1) {
                            // handle response with positive status
        
                           
                            setTotalSales(response.data.data)
                            
                            const list = JSON.stringify(response.data);

console.log("total_sales", response.data.data)
                           // alert(list)
                        
        
        
                        } else {
                            // handle response with negative status
                            //alert('You failed')
                            console.log(response);
                        }
                    })
                    .catch(function (error) {
                        // catch if errors while attempting to make request
                        console.log(error);
                    });



                    //ITEM SOLD FETCH INFO IS BELOW

                    api
                    .post('/api.php', {
                        // action is the parameter that the api/gateway/actions.php files we usually use look for
                        action: 'items-sold-today',
                        // body is the other parameter that is used with all the variables that will be passed to DataManager.class.php
                        // functions.
                        body: {
                            date: date,
                            companyid: appState.user.CompanyID
                        }
        
                    })
                    .then(function (response) {
                        console.log(response)
                        if (response.data.status === 1) {
                            // handle response with positive status
        
                           
                            setSoldItems(response.data.data)
							console.log("sold_items", response.data.data)

                
        
        
                        } else {
                            // handle response with negative status
                            //alert('You failed')
                            console.log(response);
                        }
                    })
                    .catch(function (error) {
                        // catch if errors while attempting to make request
                        console.log(error);
                    });
                    
        
            };


var cardSales = 0;
                var mobileSales = 0;
                var cashSales = 0;
                var creditSales = 0; 
               

          for  (var iy = 0; iy <totalsales.length; iy++) {
                switch(totalsales[iy].SalesType) {

                        case 'Cash':
                         cashSales++
                          break;

                        case 'Card':
                         cardSales++
                          break;

                        case 'Credit':
                         creditSales++
                         break;

                        case 'Mobile':
                                mobileSales++
                                break;

                                case 'Numerico':
                         cashSales++
                          break;

                        case 'CartÃ£o':
                         cardSales++
                          break;


                        case 'M-Pesa':
                                mobileSales++
                                break;

                                
                               default:
                               console.log('didnt receive data')
                               break;         
                }
                }


        let gross_sales = totalsales.reduce(function(prev, current) {
                return prev + +current.Total_Price
              }, 0);
              
              let total_quantity_sold = totalsales.reduce(function(prev, current) {
                return prev + +current.NoItemSold
              }, 0); 
       

              let refunded_amount = totalsales.reduce(function(prev, current) {
                return prev + +current.Refunded
              }, 0); 

let sortedQuantDsc;
              sortedQuantDsc = solditems.sort((a,b)=> {
                      return parseInt (b.ProductQty) - parseInt(a.ProductQty);
              })

let PieInfo = ''
if(solditems.length>0) {
        PieInfo = <PieGraph piedata = {solditems}/>
} else {
        PieInfo = <PieGraph  piedata = 'nothngg'/>
}



	return (
		<div className="main-page">
			<div className="header">
				<Header  />
			</div>

			<aside className="left-side">
				<Sidenav active='sales' />
			</aside>

			<label className="top_bar_label" htmlFor="top_bar">
				{' '}
				Sales<span className="top_span" style={{ display: 'block', lineHeight: '0px' }}>
					(from selected date)
				</span>
			</label>

			<div className="calender">
				<label htmlFor="datey">Date</label>

				<input
					id="datey"
					value={date} 
        onChange={({ target }) => setDate(target.value)} 
					type="date"
					width="230px"
					height="32px"
					style={{ marginLeft: '30px', borderRadius: '4px', background: '#fff', border: 'none' }}
				/>

				<button
					style={{
						width: '75px',
						height: '25px',
						backgroundColor: '#009285',
						opacity: '0.9',
						borderRadius: '8px',
						border: 'none',
						color: '#fff',
						marginLeft: '12px',
					}}
					onClick={handleSubmit} 
				>
					Display
				</button>
			</div>

			<div className="analytics">
				<h1 className="block_headers"> Payment Method</h1>
				<div style={{ width: '65%' }} >

          <BarGraph cashSales={cashSales} cardSales={cardSales} mobileSales={mobileSales} creditSales={creditSales}/>
</div>


			</div>

			<div className="item_sales">
				<h1 className="block_headers"> Itemized Sales</h1>
				<div style={{ width: '90%', height: '70%', marginLeft: '20px' }}>
					<table className="ity_sales">
						<thead>
							<th>Name</th>
							<th>Quantity</th>
							<th>Price(MT)</th>
							<th>Earnings</th>
						</thead>

						{/* {currentMembers.map((e) => { */}
                                                {solditems.slice(indexOfFirstItem, indexOfLastItem).map((e) => {

                                                        return (
                                                                <tr 
                                                                        className="innys" style={{borderBottom:'1px solid #CCCFD8'}}
                                                                >
                                                                        <td>{e.ProductName}</td>
                                                                        <td>X{e.ProductQty}</td>
                                                                        <td>{e.ProductPrice}</td>
                                                                        <td style={{color:'#009285'}}>{e.ProductQty * e.ProductPrice}</td>
                                                                        
                                                                        

                                                                </tr>

                                                        );
                                                })}

						
					</table>
					<Box style={{width:'100%',height:'20px', marginTop:'10px'}}>
					
<Pagination currentPage={currentPage} handleNextPage={handleNextPage} handleLastPage={handleLastPage} itemsPerPage={itemsPerPage} totalItems={solditems.length} paginate={paginate} />

					</Box>
				</div>
			</div>

			<div className="right_side_sale">
				<h1 className="block_headers"> Summary </h1>

				<table className="right_sales_table">
					<tr>
						<td className="bg_grey">Quantity Sold</td>
						<td className="right_align">{total_quantity_sold}</td>
					</tr>
					<tr>
						<td className="bg_grey">Gross Sales</td>
						<td className="right_align">{gross_sales} MT</td>
					</tr>
					<tr>
						<td className="bg_grey">Refunds</td>
						<td className="right_align">{refunded_amount}</td>
					</tr>
				</table>

				<Box style={{ width: '100%'}} >
				{PieInfo}
				</Box>
			</div>
		</div>
	);
}

export default SalesPage;
