import React, { useContext, useEffect, useState } from 'react';
import './InvoicePage.css';
import '../Fonts/fonts.css';
import api from './../core/api';
import Header from '../Header/Header';
import Sidenav from '../Sidenav/Sidenav';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../core/app-context';

import PlusOneIcon from '@mui/icons-material/PlusOne';
import { Center, Box, Button } from '@chakra-ui/react';
import Pagination from './Pagination';


function InvoicePage() {
	const [ items, setItems ] = useState([]);
	const [ appState, dispatch ] = useContext(AppContext);


	const [ currentPage, setCurrentPage ] = useState(1);
	const [ itemsPerPage, setItemsPerPage ] = useState(10);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;

	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const handleNextPage = () => {
		if (currentPage === items.length / itemsPerPage) {
			setCurrentPage(currentPage);
		} else {
			setCurrentPage(currentPage + 1);
		}
	};

	const handleLastPage = () => {
		if (currentPage === 1) {
			setCurrentPage(currentPage);
		} else {
			setCurrentPage(currentPage - 1);
		}
	};

	const [ day, setDay ] = useState('');
	const [totalSales, setTotalSales] = useState([])

	const handleSubmit = () => {

		api
			.post('/api.php', {
				// action is the parameter that the api/gateway/actions.php files we usually use look for
				action: 'sale-today',
				// body is the other parameter that is used with all the variables that will be passed to DataManager.class.php
				// functions.
				body: {
					date: day,
					companyid: appState.user.CompanyID
				}

			})
			.then(function (response) {
				console.log(response)
				if (response.data.status === 1) {
					// handle response with positive status

					setTotalSales(response.data.data)
					
				} else {
					// handle response with negative status
					//alert('You failed')
					console.log(response);
				}
			})
			.catch(function (error) {
				// catch if errors while attempting to make request
				console.log(error);
			});



		}


		let total_sales = totalSales.reduce(function(prev, current) {
			return Number(prev) + Number(current.Total_Price);
		}, 0);



	return (
		<div className="main-page">
			<div className="header">
				<Header />
			</div>

			<aside className="left-side">
				<Sidenav active='invoice' />
			</aside>

<div className="value">
<p className='block_amount'>{total_sales} MT </p>
	</div>
			<div className="calender">
				<label htmlFor="date">Date</label>

				<input
					id="date"
					value={day}
					 onChange={({ target }) => setDay(target.value)}
					type="date"
					width="230px"
					height="32px"
					style={{ marginLeft: '30px', borderRadius: '4px', background: '#fff', border: 'none' }}
				/>

				<button
					style={{
						width: '75px',
						height: '25px',
						backgroundColor: '#009285',
						opacity: '0.9',
						borderRadius: '8px',
						border: 'none',
						color: '#fff',
						marginLeft: '12px',
					}}
					onClick={handleSubmit}
				>
					Display
				</button>
			</div>

			<Box
				style={{
					display: 'inline-block',
					position: 'absolute',
					width: '79%',
					marginLeft: '24px',
					overflowY: 'scroll',
					height: '90%',
				}}
			>
				<div className="receipt_sales">
					<h1 className="block_headers"> Itemized Sales</h1>
					<div style={{ width: '90%', height: '70%', marginLeft: '20px' }}>
						<table className="recei_sales">
							<thead>
								<th>Receipt No</th>
								<th>Total Price</th>
								<th>No of Items Sold</th>
								<th>Purchase Type</th>
							</thead>

							
                                                {totalSales.slice(indexOfFirstItem, indexOfLastItem).map((e) => {

                                                        return (
                                                                <tr 
                                                                        className="innys" style={{borderBottom:'1px solid #CCCFD8'}}
                                                                >
                                                                        <td>{e.SalesID}</td>
                                                                        <td>{e.Total_Price}</td>
                                                                        <td>{e.NoItemSold}</td>
                                                                        <td style={{color:'#009285'}}>{e.SalesType}</td>
                                                                        
                                                                        

                                                                </tr>

                                                        );
                                                })} 
						</table>
						<Box style={{ width: '100%', height: '20px', marginTop: '10px' }}>
							<Pagination
								currentPage={currentPage}
								handleNextPage={handleNextPage}
								handleLastPage={handleLastPage}
								itemsPerPage={itemsPerPage}
								totalItems={totalSales.length}
								paginate={paginate}
							/>
						</Box>
					</div>
				</div>
			</Box>
		</div>
	);
}

export default InvoicePage;
