import logo from './logo.svg';
import './App.css';
import Login from './Login/Login'
import HomePage from './HomePage/HomePage'
import SalesPage from './SalesPage/SalesPage'
import InvoicePage from './InvoicePage/InvoicePage'
import { StateProvider } from './core/app-context';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom'

function App() {
  return (
   <StateProvider>
      <Router>
        <Switch>
          <Route exact path='/' component={Login} />
         <Route exact path='/homepage' component={HomePage} />
         <Route exact path='/salespage' component={SalesPage}/>
         <Route exact path ='/invoicepage' component={InvoicePage}/>
        </Switch>
      </Router>
    </StateProvider>
  );
}

export default App;
