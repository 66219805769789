
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useContext, useState, React, useEffect ,PureComponent} from 'react';
import { AppContext } from '../core/app-context';
import { useHistory, Redirect } from 'react-router-dom';
import api from './../core/api';

function LineGraph({values}) {

const [ appState, dispatch ] = useContext(AppContext);
const [ itemSold, setItemSold ] = useState([]);

	useEffect(() => {
		api
			.post('/api.php', {
				action: 'last-seven-days',
				body: { companyid: appState.user.CompanyID },
			})
			.then((result) => {
				console.log('item Sold', result.data.data);
				setItemSold(result.data.data);
			})
			.catch((err) => {
				console.warn(err);
			});
	}, []);

  let today_1 = new Date();
	today_1.setDate(today_1.getDate() - 1);
	let dbt1 = new Date(today_1).toISOString().slice(0, 10);
	let today1 = itemSold.filter((f) => f.TimeStamp.includes(dbt1));
	let today_1_sales = today1.reduce(function(prev, current) {
		return prev + current.ProductPrice * current.ProductQty;
	}, 0);


	let today_2 = new Date();
	today_2.setDate(today_2.getDate() - 2);
	let dbt2 = new Date(today_2).toISOString().slice(0, 10);
        let today2 = itemSold.filter((f) => f.TimeStamp.includes(dbt2));
	let today_2_sales = today2.reduce(function(prev, current) {
		return prev + current.ProductPrice * current.ProductQty;
	}, 0);


	let today_3 = new Date();
	today_3.setDate(today_3.getDate() - 3);
	let dbt3 = new Date(today_3).toISOString().slice(0, 10);
        let today3 = itemSold.filter((f) => f.TimeStamp.includes(dbt3));
	let today_3_sales = today3.reduce(function(prev, current) {
		return prev + current.ProductPrice * current.ProductQty;
	}, 0);

	let today_4 = new Date();
	today_4.setDate(today_4.getDate() - 4);
	let dbt4 = new Date(today_4).toISOString().slice(0, 10);
        let today4 = itemSold.filter((f) => f.TimeStamp.includes(dbt4));
	let today_4_sales = today4.reduce(function(prev, current) {
		return prev + current.ProductPrice * current.ProductQty;
	}, 0);

	let today_5 = new Date();
	today_5.setDate(today_5.getDate() - 5);
	let dbt5 = new Date(today_5).toISOString().slice(0, 10);
        let today5 = itemSold.filter((f) => f.TimeStamp.includes(dbt5));
	let today_5_sales = today5.reduce(function(prev, current) {
		return prev + current.ProductPrice * current.ProductQty;
	}, 0);

	let today_6 = new Date();
	today_6.setDate(today_6.getDate() - 6);
	let dbt6 = new Date(today_6).toISOString().slice(0, 10);
        let today6 = itemSold.filter((f) => f.TimeStamp.includes(dbt6));
	let today_6_sales = today6.reduce(function(prev, current) {
		return prev + current.ProductPrice * current.ProductQty;
	}, 0);

	let today_7 = new Date();
	today_7.setDate(today_7.getDate() - 7);
	let dbt7 = new Date(today_7).toISOString().slice(0, 10);
        let today7 = itemSold.filter((f) => f.TimeStamp.includes(dbt7));
	let today_7_sales = today7.reduce(function(prev, current) {
		return prev + current.ProductPrice * current.ProductQty;
	}, 0);

	console.log('total_sales', dbt7);

    const data = [
  {
    name: dbt7,
    sales:today_7_sales
  },
  {
    name: dbt6,
    sales:today_6_sales
  },
  {
    name: dbt5,
   sales:today_5_sales
  },
  {
    name: dbt4,
    sales:today_4_sales
  },
  {
    name: dbt3,
    sales:today_3_sales
  },
  {
    name: dbt2,
    sales:today_2_sales
  },
  {
    name: dbt1,
    sales:today_1_sales
    
  },
];


	
    return (
		<div>
			
        <AreaChart
          width={602}
          height={256}
          data={data}
          margin={{
            top: 5,
            right: 20,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
           <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="sales" stroke="#8884d8" fill="#94979A" />
        </AreaChart>
    
		</div>
	);
}

export default LineGraph;
