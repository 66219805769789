import React, { useContext, useEffect, useState } from "react";
import './Login.css';
import fisetiLogo from './fisetiLogo.svg';
import '../Fonts/fonts.css'
import api from "./../core/api";
import {useHistory} from 'react-router-dom';
import { AppContext } from "../core/app-context";

import {Center, Box} from '@chakra-ui/react';

function Login() {

    let history = useHistory();

 const [appState, dispatch] = useContext(AppContext);
    
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    



    const handleSubmit = () => {


        api
            .post('/api.php', {
                // action is the parameter that the api/gateway/actions.php files we usually use look for
                action: 'login',
                // body is the other parameter that is used with all the variables that will be passed to DataManager.class.php
                // functions.
                body: {
                    username: username,
                    password: password
                }

            })
            .then(function (response) {
                console.log(response)
                if (response.data.status === 1) {
                    // handle response with positive status

                   
                    dispatch({
                        type: "setUser",
                        value: response.data.data
                    });
                    
                    history.push('/homepage')

                } else {
                    // handle response with negative status
                    alert('Unable to Login')
                    console.log(response);
                }
            })
            .catch(function (error) {
                // catch if errors while attempting to make request
                console.log(error);
            });

    };







    return (<div className='background'>

        <Center h="100%" w="100%">
            <img src={fisetiLogo} className="image" alt="fiseti Logo" />
         
            <div className="form_outline">
                <h1 className='Login_thing'> Login</h1>
                <p className='login_subtitle'>Please Login to continue into the application</p>


                <input type='text' placeholder="Username" className='input_username' value={username} onChange={({ target }) => setUsername(target.value)} />

                <input type='password' placeholder="Password" className='input_password' value={password} onChange={({ target }) => setPassword(target.value)} />

                <button className='login_button' onClick={handleSubmit} type="submit">Login</button>


            </div>
            </Center>
    </div>
    );


}

export default Login