import {React, useContext, useState} from 'react';
import './Header.css';
import Fiseti from './Fiseti.svg'
import LogoTran from './LogoTran.svg'


import { useHistory, Redirect } from 'react-router-dom';

import { AppContext } from '../core/app-context';
import { Center, Box } from '@chakra-ui/react';



function Header(props) {

const [appState, dispatch] = useContext(AppContext);

const [actively, setActive] = useState(false)


	let history = useHistory();

	return (
		<nav>
			<div className="div_header">
				<div className="logo">
					<img alt='fiseti_logo' src={LogoTran}/> <img alt='fiseti' className="p_header" src={Fiseti} /> 
				</div>

			</div>
		</nav>
	);
}

export default Header;
