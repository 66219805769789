import React, { FunctionComponent } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from "recharts";

function BarGraph({cashSales, cardSales, mobileSales, creditSales}) {



 

    const data = [
  {
    name: "Cash",
    sales:cashSales
  },
  {
    name: "Mobile",
    sales:mobileSales
  },
  {
    name: "Card",
   sales:cardSales
  },

   {
    name: "Credit",
   sales:creditSales
  },

];


	
    return (
		<div>
			
         <BarChart
      width={602}
      height={200}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Bar
        dataKey="sales"
        fill="#6200EE"
         radius={[10,10,0, 0]}
         maxBarSize={ 16 } 
        label={{ position: "top" }}
      >
       
      </Bar>
    </BarChart>
		</div>
	);
}

export default BarGraph;
