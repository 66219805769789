import React, { useCallback, useState, useEffect } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import api from './../core/api';
import { AppContext } from '../core/app-context';

function PieGraph({ piedata }) {
	let p1 = 0;
	let p2 = 0;
	let p3 = 0;
	let p4 = 0;
	let p5 = 0;
  let p6 = 0

	switch (piedata.length) {

		case 1:
			p1 = piedata[0].ProductPrice * piedata[0].ProductQty;
			break;

		case 2:
			p1 = piedata[0].ProductPrice * piedata[0].ProductQty;
			p2 = piedata[1].ProductPrice * piedata[1].ProductQty;
			break;

		case 3:
			p1 = piedata[0].ProductPrice * piedata[0].ProductQty;
			p2 = piedata[1].ProductPrice * piedata[1].ProductQty;
			p3 = piedata[2].ProductPrice * piedata[2].ProductQty;
			break;

		case 4:
			p1 = piedata[0].ProductPrice * piedata[0].ProductQty;
			p2 = piedata[1].ProductPrice * piedata[1].ProductQty;
			p3 = piedata[2].ProductPrice * piedata[2].ProductQty;
			p4 = piedata[3].ProductPrice * piedata[3].ProductQty;
			break;
		case 5:
			p1 = piedata[0].ProductPrice * piedata[0].ProductQty;
			p2 = piedata[1].ProductPrice * piedata[1].ProductQty;
			p3 = piedata[2].ProductPrice * piedata[2].ProductQty;
			p4 = piedata[3].ProductPrice * piedata[3].ProductQty;
			p5 = piedata[4].ProductPrice * piedata[4].ProductQty;
			break;
      case 6:
			p1 = piedata[0].ProductPrice * piedata[0].ProductQty;
			p2 = piedata[1].ProductPrice * piedata[1].ProductQty;
			p3 = piedata[2].ProductPrice * piedata[2].ProductQty;
			p4 = piedata[3].ProductPrice * piedata[3].ProductQty;
			p5 = piedata[4].ProductPrice * piedata[4].ProductQty;
      p6 = piedata[5].ProductPrice * piedata[5].ProductQty;
			break;
		default: 
			p1 = 0;
			p2 = 0;
			p3 = 0;
			p4 = 0;
			p5 = 0;
			break;
	}

  if(piedata.length>6) {
    	p1 = piedata[0].ProductPrice * piedata[0].ProductQty;
			p2 = piedata[1].ProductPrice * piedata[1].ProductQty;
			p3 = piedata[2].ProductPrice * piedata[2].ProductQty;
			p4 = piedata[3].ProductPrice * piedata[3].ProductQty;
			p5 = piedata[4].ProductPrice * piedata[4].ProductQty;
      p5 = piedata[5].ProductPrice * piedata[5].ProductQty;
  }


	const click = () => {
		alert(p4);
	};

	const data = [
		{ name: 'Group A', value: p1},
		{ name: 'Group B', value: p2 },
		{ name: 'Group C', value: p3 },
		{ name: 'Group D', value: p4 },
		{ name: 'Group D', value: p5 },
		{ name: 'Group D', value: p6 },
	];

	const COLORS = [ '#ACB9FF', '#009285', '#855CF8', '#263238', '#503795', '#4BC3B5' ];

	return (
		<div>
			<PieChart width={400} height={400}>
				<Pie
					onClick={click}
					data={data}
					labelLine={false}
					label="name"
					outerRadius={100}
					fill="#8884d8"
					dataKey="value"
				>
					{data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
				</Pie>
			</PieChart>
		</div>
	);
}

export default PieGraph;
