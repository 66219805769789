import axios from 'axios';

//CHANGE BASE URL SO it is relavant
const BASE_URL = 'https://fiseti.com/panel';

async function get(route, params) {
    let query = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return axios.get(BASE_URL + query + route);
}

async function post(route, params) {
    return axios.post(BASE_URL + route, params);
}

const api = { get, post };

export default api;