import {React, useState} from 'react';
import './InvoicePage.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Alert, Box, Center, IconButton } from '@chakra-ui/react';

const Pagination = ({currentPage, itemsPerPage, totalItems, paginate, handleNextPage, handleLastPage }) => {
	const pageNumbers = [];

	const[isActive, setActive] = useState(false)
	const [currentP, setCurrentP]= useState(1)



	 

	return (
		<div className='right_end'>
			<div className='pagination' style={{}}>
<Box style={{ textAlign:'center', display:'inline-block', verticalAlign:'Top', marginRight:'8px'}}>
			<p className='p_wrting'> Page {currentPage} of {Math.ceil(totalItems/itemsPerPage)}</p>

			</Box>

			<Box style={{border:'1px solid #DFE0EB', width:'20px', height:'20px', textAlign:'center', verticalAlign:'Top', display:'inline-block', marginRight:'8px'}}>
			<ArrowBackIosIcon onClick={handleLastPage}  style={{color:'747587', width:'15px', height:'20px'}}/>
			</Box>

				<Box style={{border:'1px solid #DFE0EB', width:'20px', height:'20px', textAlign:'center', verticalAlign:'Top', display:'inline-block',marginRight:'8px'}}>
				<p style={{fontSize:'10px', lineHeight:'0px'}}>1</p>
				</Box>
				<Box style={{border:'1px solid #DFE0EB', width:'20px', height:'20px', textAlign:'center', verticalAlign:'Top', display:'inline-block'}}>
			<ArrowForwardIosIcon onClick={handleNextPage} style={{color:'747587', width:'15px', height:'20px'}}/>
			
			</Box>
			</div>
			
		</div>
	);
};

export default Pagination;
